import {
  useCallback, useEffect, useRef, useState,
} from 'react';

/**
 * @deprecated use fn from 'use-debounce' instead
 */
export const useDebounce = <T>(value: T, delay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay || 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useDebounced2 = <T>(value: T, delay: number):
{debouncedValue: T, updateImmediately: (newT: T) => void } => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  const timeoutRef = useRef<number>();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay || 500);
    timeoutRef.current = timeout;

    return () => {
      clearTimeout(timeout);
    };
  }, [value, delay]);

  const updateImmediately = useCallback((newValue: T) => {
    clearTimeout(timeoutRef.current);
    setDebouncedValue(newValue);
  }, []);

  return { debouncedValue, updateImmediately };
};
