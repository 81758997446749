import { AccessControlListKey } from 'types/AccessControlLists';

/**
 * Minimal permissions (one of) required to access a given layer.
 */
export const RethinkSuiteLayerGuards = {
  lca: ['calculation.company_self.view', 'review.company_childs.accept'] as AccessControlListKey[],

  ccf: ['footprint.company_self.view'] as AccessControlListKey[],

  epic: ['epd.company_self.view'] as AccessControlListKey[],

  admin: ['method.*.edit', 'announcement.*.create'] as AccessControlListKey[],

  management: ['user.user_self.edit'] as AccessControlListKey[],

} as const;
